import React, { useEffect, useRef, useState } from 'react'
import { Select, Typography, Button, Popover } from 'antd'
import { Formik, Form, ErrorMessage } from 'formik'
import axios from 'Utils/cj-axios'

const { Option } = Select

interface IAICheck {
  name: string
  code: string
  options?: { name: string; code: string }[]
  params?: string[]
  promotionType?: { name: string; code: string }[]
}

interface Props {
  onAddNewNode: any
  value: { type: string; subType?: string; promotionType?: string }
  onUpdateNode: any
}

const AiCheckNode: React.FC<Props> = ({ onAddNewNode, value, onUpdateNode }) => {
  const defaultValue = { type: '', subType: '', params: [], promotionType: '' }
  const [loading, setLoading] = useState<boolean>(false)
  const [aiChecks, setAiChecks] = useState<IAICheck[]>([])
  // local var for options (sub type)
  const [selectedSubTypeOptions, setSelectedSubTypeOptions] = useState<
    { name: string; code: string }[]
  >([])
  // local var for promotionType (using with fav products )
  const [selectedPromotionTypeOptions, setPromotionTypeOptions] = useState<
    { name: string; code: string }[]
  >([])

  useEffect(() => {
    const fetchAiChecks = async () => {
      setLoading(true)
      const resp = await axios.get('/customer-journeys/ai-suggestions')
      setAiChecks(resp.data)
      if (value) {
        const selectedItem = resp.data.find((item) => item.code === value.type)
        // Nếu item có options, cập nhật trạng thái
        if (selectedItem) {
          if (selectedItem.options) {
            setSelectedSubTypeOptions(selectedItem.options)
          }

          if (selectedItem.promotionType) {
            setPromotionTypeOptions(selectedItem.promotionType)
          }
        }
      }

      setLoading(false)
    }

    fetchAiChecks()
  }, [])

  const onSubmit = (values: {
    type: string
    subType?: string
    promotionType?: string
    params?: string[]
  }) => {
    onAddNewNode && onAddNewNode('aiCheck', values)
    onUpdateNode && onUpdateNode(values)
  }

  const handleSelectedTypeChange = (value, setFieldValue) => {
    // Tìm item được chọn trong danh sách JSON
    const selectedItem = aiChecks.find((item) => item.code === value)
    // Nếu item có options, cập nhật trạng thái
    if (selectedItem) {
      if (selectedItem.options) {
        setSelectedSubTypeOptions(selectedItem.options)
      } else {
        setSelectedSubTypeOptions([])
      }

      if (selectedItem.promotionType) {
        setPromotionTypeOptions(selectedItem.promotionType)
      } else {
        setPromotionTypeOptions([])
      }
    } else {
      setSelectedSubTypeOptions([])
      setPromotionTypeOptions([])
    }
    setFieldValue('type', value)
    setFieldValue('subType', '')
    setFieldValue('promotionType', '')
  }

  return (
    <div className="ReachabilityComponent">
      {loading ? (
        <div>Loading</div>
      ) : (
        <Formik
          initialValues={value ? value : defaultValue}
          onSubmit={(values) =>
            onSubmit({
              ...values,
              params: aiChecks.find((item) => item.code === values.type)?.params,
            })
          }
          children={({ handleChange, setFieldValue, values }) => (
            <Form className="bg-white rounded-md shadow-md mx-auto">
              <div className="form-item">
                <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-1">
                  AI Service Option
                </label>
                <div className=" flex gap-2">
                  <Select
                    onChange={(value) => handleSelectedTypeChange(value, setFieldValue)}
                    className="time-setter-format w-full"
                    value={values.type}
                    allowClear={true}
                  >
                    {aiChecks.map((option) => (
                      <Option key={option.code} value={option.code}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                </div>

                {selectedSubTypeOptions.length > 0 && (
                  <div className="form-item">
                    <label
                      htmlFor="subType"
                      className="block text-sm font-medium text-gray-700 mb-1 mt-2"
                    >
                      AI Sub type
                    </label>
                    <div className=" flex gap-2">
                      <Select
                        onChange={(value) => {
                          setFieldValue('subType', value)
                        }}
                        className="time-setter-format w-full"
                        value={values.subType}
                        allowClear={true}
                      >
                        {selectedSubTypeOptions.map((option) => (
                          <Option key={option.code} value={option.code}>
                            {option.name}
                          </Option>
                        ))}
                      </Select>
                      {/* <ErrorMessage name="subType" component="div" className='text-red-500' /> */}
                    </div>
                  </div>
                )}

                {selectedPromotionTypeOptions.length > 0 && (
                  <div className="form-item">
                    <label
                      htmlFor="promotionType"
                      className="block text-sm font-medium text-gray-700 mb-1 mt-2"
                    >
                      Promotion Type
                    </label>
                    <div className=" flex gap-2">
                      <Select
                        onChange={(value) => {
                          setFieldValue('promotionType', value)
                        }}
                        className="time-setter-format w-full"
                        value={values.promotionType}
                        allowClear={true}
                      >
                        {selectedPromotionTypeOptions.map((option) => (
                          <Option key={option.code} value={option.code}>
                            {option.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="form-item"
                style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, marginTop: 15 }}
              >
                <Button htmlType="submit" type="primary">
                  OK
                </Button>
              </div>
            </Form>
          )}
        />
      )}
    </div>
  )
}

export default AiCheckNode
