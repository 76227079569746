import React, { useState } from 'react'
import { Select, Button, DatePicker, DatePickerProps, Input } from 'antd'
import { Formik, Form, ErrorMessage } from 'formik'
import { RangePickerProps } from 'antd/lib/date-picker'
import moment from 'moment'
import * as Yup from 'yup'
import { useStoreState } from 'easy-peasy'
import { CurrentType } from 'CustomerJourneyModule'
import { MESSAGE_PARAMETER_FORMAT } from 'Containers/Campaign/MainFlow/Components/Body/Content/Form/DynamicParams/constants'

const { Option } = Select

const EXPIRED_TYPE_OPTIONS = {
  TIME_FORMAT: 'time_format',
  // DATETIME: 'dateTime',
}

const expiredTypeOptions = [
  { value: EXPIRED_TYPE_OPTIONS.TIME_FORMAT, label: 'Fixed duration' },
  // { value: EXPIRED_TYPE_OPTIONS.DATETIME, label: 'Datetime' },
]

const promotionParams = [
  {
    parameter_code: 'promoId',
    format: MESSAGE_PARAMETER_FORMAT.string,
  },
  {
    parameter_code: 'voucherName',
    format: MESSAGE_PARAMETER_FORMAT.string,
  },
  {
    parameter_code: 'voucherCode',
    format: MESSAGE_PARAMETER_FORMAT.string,
  },
  {
    parameter_code: 'voucherValue',
    format: MESSAGE_PARAMETER_FORMAT.number,
  },
  {
    parameter_code: 'voucherExpireTime',
    format: MESSAGE_PARAMETER_FORMAT.date,
  },
]

interface Props {
  value?: { wait_time?: number; time_format?: string; dateTime?: string }
  onAddNewNode: any
  onUpdateNode: any
  current: CurrentType
  parentNode?: any
}

const CreatePromo: React.FC<Props> = ({
  onAddNewNode,
  value,
  onUpdateNode,
  current,
  parentNode,
}) => {
  const initialValues = {
    wait_time: 1,
    time_format: 'day',
    dateTime: '',
  }
  const { CJTree } = useStoreState((states) => states.customerJourney)
  // const [isDirectAiCheck, setIsDirectAiCheck] = useState<boolean>(false)

  const isDirectAiCheck = parentNode?.nodeType == 'aiCheck'

  // useEffect(() => {
  //   // setIsDirectAiCheck(checkIfDirectChildOfAiCheck(CJTree, current?.nodeUuid as any))
  //   console.log({ CJTree, current, parentNode });

  // }, [
  //   CJTree,
  //   current?.nodeUuid,
  //   parentNode?.nodeType,
  // ])

  const [expiredTypeValue, setExpiredTypeValue] = useState(
    value?.time_format
      ? EXPIRED_TYPE_OPTIONS.TIME_FORMAT
      : value?.dateTime
      ? 'dateTime'
      : EXPIRED_TYPE_OPTIONS.TIME_FORMAT
  )

  const getValidationSchema = (expiredTypeValue) =>
    Yup.object().shape({
      wait_time: Yup.number().when([], {
        is: () => expiredTypeValue === EXPIRED_TYPE_OPTIONS.TIME_FORMAT, // Kiểm tra giá trị expiredTypeValue
        then: Yup.number().required('Wait time is required').min(1, 'Wait time must be at least 1'),
        otherwise: Yup.number().notRequired(),
      }),

      time_format: Yup.string().when([], {
        is: () => expiredTypeValue === EXPIRED_TYPE_OPTIONS.TIME_FORMAT,
        then: Yup.string().required('Time format is required'),
        otherwise: Yup.string().notRequired(),
      }),

      // dateTime: Yup.date().when([], {
      //   is: () => expiredTypeValue === EXPIRED_TYPE_OPTIONS.DATETIME,
      //   then: Yup.date().required('Datetime are required'),
      //   otherwise: Yup.date().notRequired(),
      // }),
    })

  const onSubmit = (values: { wait_time?: number; time_format?: string; dateTime?: string }) => {
    let payload = {}

    if (expiredTypeValue === EXPIRED_TYPE_OPTIONS.TIME_FORMAT) {
      payload = {
        ...payload,
        wait_time: values?.wait_time,
        time_format: values?.time_format,
        params: promotionParams,
      }
    }
    // else if (expiredTypeValue === EXPIRED_TYPE_OPTIONS.DATETIME) {
    //   payload = {
    //     ...payload,
    //     dateTime: values?.dateTime,
    //     params: promotionParams
    //   };
    // }

    console.log('Final Payload:', payload)

    onAddNewNode && onAddNewNode('createPromotion', payload)
    onUpdateNode && onUpdateNode(payload)
  }

  const handleExpiredTypeOptionChange = (value, resetForm) => {
    setExpiredTypeValue(value)
    resetForm() // Reset form về trạng thái ban đầu
  }

  return (
    <div className="ReachabilityComponent">
      {isDirectAiCheck ? (
        <Formik
          initialValues={value ? value : initialValues}
          onSubmit={(values) => onSubmit(values)}
          validationSchema={getValidationSchema(expiredTypeValue)}
          children={({ handleChange, setFieldValue, resetForm, values }) => (
            <Form className="bg-white rounded-md shadow-md mx-auto">
              {/* Time Delay Option */}
              <div className="mb-4">
                <label
                  htmlFor="expiredTypeValue"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Expired Type Option
                </label>
                <Select
                  id="expiredTypeValue"
                  className="w-full !rounded-lg"
                  placeholder="Select Expired Type Option"
                  onChange={(value) => handleExpiredTypeOptionChange(value, resetForm)}
                  value={expiredTypeValue}
                  style={{
                    borderRadius: '8px', // Bo tròn góc
                  }}
                  // allowClear={true}
                  disabled={true}
                >
                  {expiredTypeOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>

              {/* Conditional Fields */}
              {expiredTypeValue === EXPIRED_TYPE_OPTIONS.TIME_FORMAT && (
                <div className="form-item">
                  <label
                    htmlFor="expiredTypeValue"
                    className="block text-sm font-medium text-gray-500 mb-1"
                  >
                    Set the amount of time which the promo will remain active
                  </label>
                  <div className=" flex gap-2">
                    <Input
                      className="mr-2 w-1/2"
                      onChange={handleChange}
                      name="wait_time"
                      value={values.wait_time}
                      disabled={true}
                    />
                    <Select
                      onChange={(value) => {
                        setFieldValue('time_format', value)
                      }}
                      className="time-setter-format w-1/2"
                      value={values.time_format}
                      disabled={true}
                      allowClear={true}
                    >
                      {/* <Option value="min">mins</Option> */}
                      {/* <Option value="hour">hours</Option> */}
                      <Option value="day">days</Option>
                    </Select>
                  </div>
                  <ErrorMessage name="wait_time" component="div" className="text-red-500" />
                  <ErrorMessage name="time_format" component="div" className="text-red-500" />
                </div>
              )}

              {/* {expiredTypeValue === EXPIRED_TYPE_OPTIONS.DATETIME && (
                <div className="form-item">
                  <label htmlFor="expiredTypeValue" className="block text-sm font-medium text-gray-500 mb-1">
                    Set the expiration date for the promotion
                  </label>
                  <DatePicker
                    className='w-full'
                    showTime
                    format={'YYYY-MM-DDTHH:mm:ss'}
                    placeholder='Select datetime'
                    // defaultPickerValue={moment(value?.dateTime).format() as any}
                    value={values.dateTime ? moment(values.dateTime) : null}
                    // defaultValue={moment(value?.dateTime).format() as any}
                    onChange={(
                      value: DatePickerProps['value'] | RangePickerProps['value'],
                      dateString: [string, string] | string
                    ) => {
                      if (expiredTypeValue === 'dateTime') {
                        const utcDate = moment.tz(dateString, 'Asia/Bangkok').utc()
                        setFieldValue('dateTime', utcDate.format())
                      }
                    }}
                    onOk={(value: DatePickerProps['value'] | RangePickerProps['value']) => { }}
                  />
                  <ErrorMessage name="dateTime" component="div" className='text-red-500' />

                </div>
              )} */}

              <div
                className="form-item"
                style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, marginTop: 15 }}
              >
                <Button htmlType="submit" type="primary">
                  OK
                </Button>
              </div>
            </Form>
          )}
        />
      ) : (
        <div className="text-sm font-medium text-red-500">Please select AI Suggestion first</div>
      )}
    </div>
  )
}

export default CreatePromo
