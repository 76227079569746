import { Button, DatePicker, Input, Select, TimePicker } from 'antd'
import { Form, Formik } from 'formik'
import moment, { Moment } from 'moment'
import React from 'react'
const { Option } = Select

interface Props {
  onAddNewNode: any
  value: {
    subType: 'durationWait' | 'specificTimeWait' | 'dateWait' | 'dayOfWeekWait',
    waitTime: number
    timeFormat: 'min' | 'hour' | 'day'
    time: string,
    dateTime: Moment,
    dayOfWeek: string
  }
  onUpdateNode: any
  current: any,
  setCurrent: any
}

const descriptionSubTypeMap = {
  durationWait: 'Set amount of time user should wait before moving forward',
  specificTimeWait: 'Set the specific time to continue the journey. If the time already passed, user will wait till the next day',
  dateWait: 'Set the date and time to continue the journey. If the time already passed, user will immediately proceed to the next journey element',
  dayOfWeekWait: 'Set the specific day of week to continue the journey. If the time already passed, user will wait till the next week'
}

const Wait: React.FC<Props> = (props) => {
  const { onAddNewNode, value, onUpdateNode } = props
  const defaultValue = { subType: 'durationWait' , waitTime: 5, timeFormat: 'min', time: null, dateTime: null, dayOfWeek: undefined }

  const onSubmit = (values: any) => {
    const transformedValues = {...values, time: values?.time ? values?.time?.format("HH:mm") : null, dateTime: values?.dateTime ? values?.dateTime?.toISOString() : null}
    onAddNewNode && onAddNewNode('wait', transformedValues)
    onUpdateNode && onUpdateNode(transformedValues)
  }

  return (
    <div className="WaitComponent">
      <Formik
        initialValues={value ? {...value, time: value?.time ? moment(value?.time) : null, dateTime: value?.dateTime ? moment(value?.dateTime) : null} : defaultValue}
        onSubmit={(values) => onSubmit(values)}
        children={({ handleChange, setFieldValue, values }) => (
          // <Form>
          //   <div className="form-item time-setter">
          //     {/* <Select
          //       onChange={(value) => setFieldValue('type', value)}
          //       // name="time_format"
          //       // className="time-setter-format w"
          //       value={values.type}
          //     >
          //       <Option value="fixed_duration">Fixed duration</Option>
          //       <Option value="specific_time">Specific time</Option>
          //       <Option value="date">Date</Option>
          //       <Option value="day_of_week">Day of week</Option>
          //     </Select> */}
          //     <Input
          //       className="mr-2 w-1/2"
          //       onChange={handleChange}
          //       name="wait_time"
          //       value={values.wait_time}
          //     />
          //     <Select
          //       onChange={(value) => setFieldValue('time_format', value)}
          //       // name="time_format"
          //       className="time-setter-format w-1/2"
          //       value={values.time_format}
          //     >
          //       <Option value="min">mins</Option>
          //       <Option value="hour">hours</Option>
          //       <Option value="day">days</Option>
          //     </Select>
          //   </div>
          //   <div
          //     className="form-item"
          //     style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, marginTop: 15 }}
          //   >
          //     <Button htmlType="submit" type="primary">
          //       OK
          //     </Button>
          //   </div>
          // </Form>
          <Form>
            {/* <div className="form-item">
              <label>Point Name</label>
              <Input
                name="pointName"
                onChange={handleChange}
                value={values.pointName}
                placeholder="Enter point name"
              />
            </div> */}

            <div className="form-item">
              <label>Time Delay Option</label>
              <Select
                onChange={(value) => setFieldValue('subType', value)}
                value={values.subType}
                placeholder="Select time delay option"
                style={{ width: '100%' }}
              >
                <Option value="durationWait">Fixed Duration</Option>
                <Option value="specificTimeWait">Specific Time</Option>
                <Option value="dateWait">Date</Option>
                <Option value="dayOfWeekWait">Day of Week</Option>
              </Select>
            </div>

            <div className="form-item">
              <label>{descriptionSubTypeMap[values.subType]}</label>
            </div>

            {values.subType === 'durationWait' && <div className="form-item">
              <label>Wait Till Specific Time</label>
              <div style={{ display: 'flex', gap: '8px' }}>
                <Input
                  onChange={handleChange}
                  name="waitTime"
                  value={values.waitTime}
                  style={{ width: '100%' }}
                />
                <Select
                  onChange={(value) => setFieldValue('timeFormat', value)}
                  // name="time_format"
                  value={values.timeFormat}
                  style={{ width: '100%' }}
                >
                  <Option value="min">mins</Option>
                  <Option value="hour">hours</Option>
                  <Option value="day">days</Option>
                </Select>
              </div>
            </div>}

            {values.subType === 'specificTimeWait' && <div className="form-item">
              <label>Specific Time</label>
              <TimePicker
                placeholder='HH:mm'
                value={values?.time} // Controlled value for Formik
                format="HH:mm"
                onChange={(time) => {
                  setFieldValue('time', time)
                }} // Update Formik state on change
                style={{ width: '100%' }}
              />
            </div>}

            {values.subType === 'dateWait' && (
              <div className="form-item">
                <label>Date</label>
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  onChange={(date) => setFieldValue('dateTime', date)}
                  value={values?.dateTime}
                  style={{ width: '100%' }}
                />
              </div>
            )}

            {values.subType === 'dayOfWeekWait' && <div className="form-item">
              <label>Day of week</label>
              <div style={{ display: 'flex', gap: '8px' }}>
                <Select
                  onChange={(value) => setFieldValue('dayOfWeek', value)}
                  value={values.dayOfWeek}
                  placeholder="Select day of week"
                  style={{ width: '100%' }}
                >
                  <Option value="mon">Monday</Option>
                  <Option value="tue">Tuesday</Option>
                  <Option value="wed">Wednesday</Option>
                  <Option value="thu">Thursday</Option>
                  <Option value="fri">Friday</Option>
                  <Option value="sat">Saturday</Option>
                  <Option value="sun">Sunday</Option>
                </Select>
                <TimePicker
                  placeholder='HH:mm'
                  value={values.time ? moment(values.time, 'HH:mm') : null} // Controlled value for Formik
                  format="HH:mm"
                  onChange={(time) => {
                    setFieldValue('time', time)
                  }} // Update Formik state on change
                  style={{ width: '100%' }}
                />
              </div>
            </div>}
            <div
              className="form-item"
              style={{ display: 'flex', justifyContent: 'flex-end', margin: 0, marginTop: 15 }}
            >
              <Button htmlType="submit" type="primary">
                OK
              </Button>
            </div>
          </Form>
        )}
      />
    </div>
  )
}

export default Wait
